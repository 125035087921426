import Vue from "vue";
import VueRouter from "vue-router";
import header from "/src/view/header.vue"

Vue.use(VueRouter);

const router = new VueRouter({
	routes: [
		{
			name: "/",
			path: "/",
			component: () => import("../view/index.vue"),
		},
		{
			path: "/header",
			name: "/header",
			component: header,
			children: [
				{
					name: "/order",
					path: "/order",
					component: () => import("../view/order.vue"),
				},
				{
					name: "/order_add",
					path: "/order_add",
					component: () => import("../view/order_add.vue"),
				},
				{
					name: "/car",
					path: "/car",
					component: () => import("../view/car.vue"),
				},
				{
					name: "/car_add",
					path: "/car_add",
					component: () => import("../view/car_add.vue"),
				},
				{
					name: "/custom",
					path: "/custom",
					component: () => import("../view/custom.vue"),
				},
				{
					name: "/custom_add",
					path: "/custom_add",
					component: () => import("../view/custom_add.vue"),
				},
				{
					name: "/supplier",
					path: "/supplier",
					component: () => import("../view/supplier.vue"),
				},
				{
					name: "/supplier_add",
					path: "/supplier_add",
					component: () => import("../view/supplier_add.vue"),
				},
				{
					name: "/info",
					path: "/info",
					component: () => import("../view/info.vue"),
				},
			]
		},
	],
});

export default router;