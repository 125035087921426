<template>
	<div class="header_content">
		<div class="nav_box">
			<div class="nav_top">
				<!-- <el-image class="nav_logo" :src="nav1" fit="contain" /> -->
				<div class="nav_logo">LOGO</div>
				<div>再生资源企业-线下流程线上网络信息化系统</div>
			</div>
			<div class="nav_list">
				<div v-for="(item,index) in nav_list" class="nav_item" :class="{'active':item.url_list.some(u => u == activeRouter)}" @click="toPage(item)">
					<el-image class="nav_icon" :src="item.url_list.some(u => u == activeRouter)?item.is_icon:item.icon" fit="contain" />{{item.name}}
				</div>
			</div>
		</div>
		<div class="nav_content">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import { getCurrentInstance, watch, h } from 'vue'
	import nav1 from '/src/assets/img/nav1.png'
	import nav1_in from '/src/assets/img/nav1_in.png'
	import nav2 from '/src/assets/img/nav2.png'
	import nav2_in from '/src/assets/img/nav2_in.png'
	import nav3 from '/src/assets/img/nav3.png'
	import nav3_in from '/src/assets/img/nav3_in.png'
	import nav4 from '/src/assets/img/nav4.png'
	import nav4_in from '/src/assets/img/nav4_in.png'
	import nav5 from '/src/assets/img/nav5.png'
	import nav5_in from '/src/assets/img/nav5_in.png'
	var that
	export default {
		data() {
			return {
				nav1: nav1,
				activeRouter: '',//当前所属页面路径
				nav_list: [
					{ name: '订单管理', icon: nav1, is_icon: nav1_in, url: '/order', url_list:['/order','/order_add']},
					{ name: '车辆管理', icon: nav2, is_icon: nav2_in, url: '/car', url_list:['/car','/car_add']},
					{ name: '客户管理', icon: nav3, is_icon: nav3_in, url: '/custom', url_list:['/custom','/custom_add']},
					{ name: '供应商管理', icon: nav4, is_icon: nav4_in, url: '/supplier', url_list:['/supplier','/supplier_add']},
					{ name: '企业信息管理', icon: nav5, is_icon: nav5_in, url: '/info', url_list:['/info']}
				]
			};
		},
		watch: {
			$route(newVal, oldval) {
				// console.log(newVal.path, oldval.path)
				that.activeRouter = newVal.path
			}
		},
		mounted() {
			that = this
			
			// 判断当前所在路径
			const { proxy } = getCurrentInstance()
			watch(() => proxy.$router.currentRoute, (newValue, oldValue) => {
				// console.log(newValue.path)
				that.activeRouter = newValue.path
			}, { immediate: true })
		},
		methods: {
			toPage(item) {
				if(that.activeRouter == item.url) {
					return
				}
				that.activeRouter = item.url
				that.$router.push({ path: item.url })
			},
		},
	};
</script>

<style scoped lang="scss">
	.header_content {
		display: flex;
		height: 100%;
		width: 100%;
		background-color: #f3f3f8;
		.nav_box {
			width: 269px;
			min-width: 269px;
			height: 100%;
			background: #FBFBFE;
			border-right: 1px solid #dde0e6;
			.nav_top {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 32px 22px 35px 22px;
				font-size: 18px;
				color: #3A3A3B;
				border-bottom: 1px solid #d7d7d7;
				line-height: 31px;
				text-align: center;
				.nav_logo {
					margin-bottom: 14px;
					display: block;
					width: 106px;
					height: 95px;
					font-weight: bold;
					font-size: 32px;
					color: #333;
					line-height: 95px;
					text-align: center;
				}
			}
			.nav_list {
				padding: 18px 12px;
				.nav_item {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					padding: 0 17px;
					height: 56px;
					font-size: 18px;
					color: #505769;
					border-radius: 10px;
					cursor: pointer;
					.nav_icon {
						display: block;
						margin-right: 18px;
						width: 31px;
						height: 31px;
					}
				}
				.nav_item.active {
					color: #fff;
					background: #507AF9;
				}
			}
		}
		.nav_content {
			flex: 1;
			width: calc(100% - 270px);
		}
	}
</style>