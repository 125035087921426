<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {},
		methods: {
			bodyScale() {
				var devicewidth = document.documentElement.clientWidth;
				var scale1 = devicewidth / 1920; // 分母——设计稿的尺寸宽度
				var deviceheight = document.documentElement.clientHeight;
				var scale2 = deviceheight / 1080; // 分母——设计稿的尺寸宽度
				var scale = scale1 < scale2 ? scale1 : scale2;
				// document.body.style.zoom = scale-0.07113;
				//页面尺寸放大
				document.body.style.zoom = scale
				//解决zoom放大缩小导致echarts位置偏移问题
				let strScale = `scale(${scale})`;
				var obj = {
					zoom: 1 / scale,
					transform: strScale,
					transformOrigin: "0 0",
				};
				this.insertCss("canvas", obj);
			},
			insertCss(select, styles) {
				console.log(styles);
				if (document.styleSheets.length === 0) {
					//如果没有style标签,则创建一个style标签
					var style = document.createElement("style");
					document.head.appendChild(style);
				}
				var styleSheet = document.styleSheets[document.styleSheets.length - 1]; //如果有style 标签.则插入到最后一个style标签中
				var str = select + " {"; //插入的内容必须是字符串,所以得把obj转化为字符串
				for (var prop in styles) {
					str +=
						prop.replace(/([A-Z])/g, function(item) {
							//使用正则把大写字母替换成 '-小写字母'
							return "-" + item.toLowerCase();
						}) +
						":" +
						styles[prop] +
						";";
				}
				str += "}";
				styleSheet.insertRule(str, styleSheet.cssRules.length); //插入样式到最后一个style标签中的最后面
			},
		},
		mounted() {
			// window.onload = window.onresize = () => {
			//   this.bodyScale();
			// };
			const token = window.sessionStorage.getItem('token')
			if (!token) {
				this.$router.push('/')
			}
			window.addEventListener("mousewheel", function(event) {
				if (event.ctrlKey === true || event.metaKey) {
					event.preventDefault()
				}
			}, {
				passive: false
			})
		},
	}
</script>

<style>
	body {
		width: 100%;
		height: 100%;
		background: #000;
	}

	li {
		list-style: none;
	}

	a {
		text-decoration: none;
	}

	img {
		vertical-align: middle;
	}

	* {
		margin: 0%;
		padding: 0%;
	}

	::-webkit-scrollbar {
		/* display: none; */
	}

	#app {
		width: 100%;
		height: 100vh;
		min-height: 100vh;
	}

	img {
		vertical-align: middle;
	}

	#app {
		margin: auto;
	}
</style>